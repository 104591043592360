import { useQuery } from "react-query";

const getArticle = async (slug) => {
  const response = await fetch(process.env.PUBLIC_URL + "/articles/" + slug + ".md");
  const text = await response.text();
  if (!text || text.startsWith("<!DOCTYPE")) {
    return {};
  }

  let noImage;
  let noThumbnail;
  let title = text.split("\n", 1)[0].replace(/#\s+/, "");

  if (title.includes("<!--no-image-->")) {
    title = title.replace("<!--no-image-->", "");
    noImage = true;
  }

  if (title.includes("<!--no-thumbnail-->")) {
    title = title.replace("<!--no-thumbnail-->", "");
    noThumbnail = true;
  }

  return {
    article: text,
    title,
    date: slug,
    thumbnail: noThumbnail ? null : "/images/" + slug + "_thumbnail.png",
    image: noImage ? null : "/images/" + slug + ".png",
    url: "/" + slug
  };
};

export const useArticle = (slug) => {
  return useQuery("article_" + slug, () => getArticle(slug), {
    refetchOnWindowFocus: false
  });
};