import React from "react";
import { animated } from "react-spring";

const trans1 = (x, y) => `translate3d(${x / 20}px,${y / 20}px,0)`;
const trans2 = (x, y) => `translate3d(${x / 5 - 350}px,${y / 5 + 100}px,0)`;
const trans3 = (x, y) => `translate3d(${-x / 16 + 350}px,${-y / 16 - 100}px,0)`;

export const Parallax = (props) => {
  const { springProps } = props;
  const imageStyle = (trans) => ({
    height: 600,
    zIndex: 999,
    transform: springProps.xy.interpolate(trans)
  });

  return (
    <div style={{
      height: 600,
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <animated.img src="/island3.png"
                    style={imageStyle(trans3)} />
      <animated.img src="/island1.png"
                    style={imageStyle(trans1)} />
      <animated.img src="/island2.png"
                    style={imageStyle(trans2)} />
    </div>
  );
};