import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { PickaxeIcon } from "./PickaxeIcon";
import classnames from "classnames";
import { useLink } from "../hooks/useLink";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  box: {
    position: "sticky",
    width: "100%",
    top: 0,
    left: 0
  },
  header: {
    fontFamily: "Montserrat",
    color: theme.palette.grey["300"],
    backgroundColor: theme.palette.background.default,
    transition: "box-shadow .5s"
  },
  headerTitle: {
    cursor: "pointer",
  },
  button: {
    borderRadius: 0,
    color: "inherit",
    fontWeight: "lighter",
    "&.selected": {
      borderBottom: "solid 2px white",
      fontWeight: "bolder"
    }
  },
}));

const links = [
  {
    label: "rules",
    value: "/rules"
  },
  {
    label: "store",
    value: "https://store.mineclick.net"
  },
  {
    label: "wiki",
    value: "https://wiki.mineclick.net"
  },
  {
    label: "status",
    value: "https://ender.mineclick.net"
  },
];

export const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const link = useLink();
  const [shadow, setShadow] = useState("");
  const [zIndex, setZIndex] = useState(998);

  const listenScrollEvent = () => {
    setShadow(window.scrollY > 20 ? "0px -2px 15px #000" : "");
    setZIndex(window.scrollY > 20 ? 1000 : 998);
  };
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);

    return () =>
      window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const createLinks = () => {
    return links.map((item, index) =>
      <Button key={index}
              sx={{
                px: { xs: 0, md: 3 },
                mr: { xs: 0, md: 1 },
              }}
              className={classnames(classes.button, history.location.pathname === item.value ? "selected" : "")}
              size={"large"}
              href={item.value.startsWith("http") ? item.value : undefined}
              onClick={() => {
                if (!item.value.startsWith("http")) {
                  link(item.value)();
                }
              }}
      >
        {item.label}
      </Button>
    );
  };

  return <Box className={classes.box} style={{
    zIndex: zIndex
  }}>
    <Grid container
          direction={"row"}
          justifyContent={{ xs: "space-between", md: "start" }}
          alignItems={"center"}
          sx={{
            px: { xs: 2, md: 4 },
          }}
          style={{
            boxShadow: shadow
          }}
          className={classes.header}>
      <Grid item>
        <Grid container
              direction={"row"}
              alignItems={"center"}
              wrap={"nowrap"}
              onClick={link("/")}
              className={classes.headerTitle}>
          <Grid item sx={{ mr: 2, display: { xs: "none", md: "block" } }}>
            <PickaxeIcon style={{ fontSize: 30 }} />
          </Grid>
          <Grid item sx={{ mr: { xs: 2, md: 8 } }}>
            <Typography color={"inherit"} fontSize={{ xs: 30, md: 40 }}>
              MINECLICK
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {createLinks()}
      </Grid>
    </Grid>
  </Box>;
};