import { useHistory, useLocation } from "react-router-dom";

export const useLink = () => {
  const history = useHistory();
  const vars = useLocation().search;

  return (link) => (event) => {
    if (event) {
      event.preventDefault();
    }

    history.push(link + vars);
  };
};