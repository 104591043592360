import React, { useState } from "react";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Articles from "../components/Articles";
import { Parallax } from "../components/Parallax";
import { useSpring } from "react-spring";

const useStyles = makeStyles(theme => ({
  box: {
    flexGrow: 1,
    color: theme.palette.grey["300"]
  }
}));

const Title = (props) => {
  return <Typography {...props} variant={"h3"} fontWeight={"lighter"} />;
};

export default function Home() {
  const classes = useStyles();
  const [serverButtonText, setServerButtonText] = useState("Click to copy server IP");
  const [springProps, springSet] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 200, friction: 800 }
  }));

  const copyToClipboard = (text) => navigator.clipboard.writeText(text);
  const onServerButtonClick = () => {
    setServerButtonText("Copied!");

    setTimeout(() => {
      setServerButtonText("Click to copy server IP");
    }, 5000);

    return copyToClipboard("play.mineclick.net");
  };

  return <Box className={classes.box}
              onMouseMove={({ clientX: x, clientY: y }) =>
                springSet({ xy: [x - window.innerWidth / 2, y - window.innerHeight / 2] })}
  >
    <Grid container
          justifyContent={"space-between"}
          columnSpacing={4}
          sx={{
            px: { xs: 2, md: 8, xl: 28 },
          }}>
      <Grid item xs={12} md={6} sx={{
        pt: { xs: 8, md: 8 },
        pb: { xs: 8, md: 8 },
        display: "flex",
        flexDirection: "column",
        justifyContent: "start"
      }}>
        <Title>
          Incremental
        </Title>
        <Title>
          Clicker
        </Title>
        <Title>
          Minecraft Server
        </Title>
        <Typography fontWeight={"lighter"} mt={2}>
          MineClick is a unique Minecraft server that features an
          incremental/idle clicker gamemode. Start your adventure today by
          joining the server at play.mineclick.net
        </Typography>
        <Box mt={6}>
          <Tooltip title={serverButtonText} placement={"top"} arrow>
            <Button variant={"contained"}
                    sx={{ mr: 2 }}
                    color={"success"}
                    size={"large"}
                    onClick={onServerButtonClick}>
              play.mineclick.net
            </Button>
          </Tooltip>
          <Tooltip title="Click to join Discord" placement={"top"} arrow>
            <Button variant={"contained"}
                    size={"large"}
                    target={"_blank"}
                    href={"https://discord.gg/hurPy3P"}>
              Discord
            </Button>
          </Tooltip>
        </Box>
      </Grid>
      <Grid item
            xs={6}
            display={{ xs: "none", md: "block" }}
            style={{ textAlign: "center" }}>
        <Parallax springProps={springProps} />
      </Grid>
    </Grid>
    <Articles />
  </Box>;
}