import { useLocation } from "react-router-dom";

export const useUrlQuery = () => {
  const vars = useLocation().search.substring(1).split("&");

  return vars.reduce((acc, item) => {
    const [key, value] = item.split("=");
    acc[key] = decodeURIComponent(value);
    return acc;
  }, {});
};