import { createTheme } from "@mui/material";

export default createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#5663c4",
    },
    secondary: {
      main: "#78d9fc",
    },
    background: {
      default: "#222641"
    }
  },
});