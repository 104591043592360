import React from "react";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import { useArticle } from "../hooks/useArticle";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  colors,
  Grid,
  IconButton,
  Skeleton,
  Tooltip,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLink } from "../hooks/useLink";
import { timeAgo } from "../utils/ArticlesList";
import { Redirect } from "react-router-dom";
import { Close } from "@mui/icons-material";

const useStyles = makeStyles(theme => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "relative",
    backgroundColor: "#1d2037",
    color: theme.palette.grey["300"],
    marginBottom: theme.spacing(4),
    "&:hover": {
      "& $cardContent": {
        "&::before": {
          height: "55%"
        }
      },
      "& $cardMedia": {
        transform: "scale(1.1)",
      },
    }
  },
  cardContent: {
    flexGrow: 1,
    zIndex: 1,
    // "&::before": {
    //   content: "''",
    //   display: "block",
    //   position: "absolute",
    //   left: 0,
    //   bottom: 0,
    //   zIndex: 0,
    //   width: "100%",
    //   height: "50%",
    //   borderRadius: "1rem",
    //   transition: "height .5s",
    //   background: `linear-gradient(to top, #000, ${theme.palette.background.default})`,
    //   clipPath:
    //     "polygon(0% 100%, 0% 35%, 0.3% 33%, 1% 31%, 1.5% 30%, 2% 29%, 2.5% 28.4%, 3% 27.9%, 3.3% 27.6%, 5% 27%,95% 0%,100% 0%, 100% 100%)",
    // },
  },
  cardMedia: {
    cursor: "pointer",
    transition: "transform 1.5s"
  },
  cardActions: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    justifyContent: "space-between"
  },
  skeleton: {
    backgroundColor: theme.palette.grey["800"]
  },
  articleImageBox: {
    display: "flex",
    justifyContent: "center",
    background: "#202124"
  },
  articleImage: {
    width: "100%",
    objectFit: "cover",
    maxHeight: 400
  },
  articleBox: {
    background: "#202124",
    color: "#bdc1c6",
    overflow: "hidden",
    padding: "10px 20px 50px 20px",
    [theme.breakpoints.up("md")]: {
      padding: "10px 60px 50px 60px",
    },
    "& a": {
      color: "#8ab4f8"
    },
    "& img": {
      maxWidth: "100%",
      margin: "0 auto",
      display: "block"
    }
  },
  closeButton: {
    margin: theme.spacing(0.5),
    position: "absolute",
    right: 0,
    color: theme.palette.grey[500],
    backgroundColor: "#bdc1c6",
    "&:hover": {
      backgroundColor: "#fff"
    }
  },
}));

export const Article = ({ slug, isThumbnail }) => {
  const classes = useStyles();
  const link = useLink();
  const { data, isLoading } = useArticle(slug);
  const { article, title, thumbnail, image, date, url } = data || {};

  if (isThumbnail) {
    return <Card className={classes.card} elevation={6}>
      {isLoading ?
        <Skeleton variant={"rectangular"} height={250} width={"100%"}
                  className={classes.skeleton} />
        :
        <CardMedia
          onClick={link(url)}
          className={classes.cardMedia}
          component="img"
          height="250"
          image={thumbnail ? thumbnail : image}
          alt="Thumbnail image"
        />
      }
      <CardContent className={classes.cardContent}>
        <Box style={{
          position: "relative"
        }}>
          <Grid container justifyContent={"space-between"}>
            <Grid item style={{ flexGrow: 1 }}>
              <Typography variant="h5"
                          component="div"
                          style={{ textTransform: "uppercase" }}
              >
                {isLoading ? <Skeleton className={classes.skeleton} /> : title}
              </Typography>
            </Grid>
          </Grid></Box>
      </CardContent>
      <CardActions className={classes.cardActions}>
        <Tooltip title={date || ""}
                 placement={"top"}
                 arrow>
          <Typography variant={"body2"} color={colors.grey["300"]}>
            {isLoading ?
              <Skeleton className={classes.skeleton}
                        variant={"text"}
                        width={100} />
              : timeAgo(date)}
          </Typography>
        </Tooltip>
        {isLoading ?
          <Skeleton className={classes.skeleton} width={100} height={24}
                    variant={"text"} />
          : <Button color={"warning"} href={url}
                    onClick={link(url)}>Read More</Button>}
      </CardActions>
    </Card>;
  }

  // Full page view
  if (isLoading) {
    return <Box sx={{ height: "100%" }} />;
  } else if (article) {
    document.title = "MineClick - " + title;

    return <React.Fragment>
      <IconButton aria-label="Close"
                  className={classes.closeButton}
                  onClick={link("/")}
      >
        <Close width={50} height={50} sx={{
          fontSize: 30,
          color: "#000"
        }} />
      </IconButton>
      {!!image && <Box className={classes.articleImageBox}>
        <img className={classes.articleImage}
             src={image}
             alt={"Header image"} />
      </Box>}
      <Box className={classes.articleBox}>
        <ReactMarkdown rehypePlugins={[rehypeRaw]}>{article}</ReactMarkdown>
      </Box>
    </React.Fragment>;
  } else {
    return <Redirect to="/" />;
  }
};