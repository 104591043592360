import { Route, Switch } from "react-router-dom";
import Home from "./pages/Home";
import React from "react";

export default function RouterSwitch() {
  return <Switch>
    <Route path="/">
      <Home />
    </Route>
  </Switch>;
}