const itemsPerPage = 3;
const articles = [
  "2021-11-28",
  "2021-04-29",
  "2021-01-30",
  "2019-05-19",
  "2018-12-01",
  "2018-07-13",
  "2018-06-17",
  "2018-06-10",
];

export const getPageCount = () => {
  return Math.ceil(articles.length / itemsPerPage);
};

export const getArticles = (page) => {
  const from = page * itemsPerPage;
  const to = page * itemsPerPage + itemsPerPage;

  const list = [];
  for (let i = from; i < to && i < articles.length; i++) {
    list.push(articles[i]);
  }

  return list;
};

export const timeAgo = (slug) => {
  const split = slug.split("-");
  const date = Date.UTC(split[0], Number.parseInt(split[1], 10) - 1, split[2]);
  const now = new Date();

  if (now - date > (1000 * 60 * 60 * 24 * 30)) {
    const d = new Date(date);
    return d.toISOString().split("T")[0];
  }

  const diff = Math.floor((Date.UTC(now.getFullYear(), now.getMonth(), now.getDate()) - date) / (1000 * 60 * 60 * 24));

  if (diff <= 0) {
    return "Today";
  }

  return diff + " days ago";
};