import React from "react";
import RouterSwitch from "./RouterSwitch";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme";
import { Header } from "./components/Header";
import StyledEngineProvider from "@material-ui/core/StyledEngineProvider";

const queryClient = new QueryClient();

const App = () => {
  const getBasename = path => path.substr(0, path.lastIndexOf("/"));

  return <React.Fragment>
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <QueryClientProvider client={queryClient}>
          <Router basename={getBasename(window.location.pathname)}>
            <Box>
              <Header />
              <RouterSwitch />
            </Box>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </React.Fragment>;
};

export default App;
