import React from "react";
import {
  Box,
  colors,
  Fade,
  Grid,
  Modal,
  Pagination,
  PaginationItem,
  Paper
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Article } from "./Article";
import { Link } from "react-router-dom";
import { useUrlQuery } from "../hooks/useUrlQuery";
import { getArticles, getPageCount } from "../utils/ArticlesList";
import { usePath } from "../hooks/usePath";
import { useLink } from "../hooks/useLink";

const useStyles = makeStyles(theme => ({
  box: {
    flexGrow: 1,
    backgroundColor: "#131523",
    color: "black",
    [theme.breakpoints.up("md")]: {
      "&::before": {
        background: "#131523",
        bottom: 0,
        content: "''",
        display: "block",
        height: "40%",
        left: 0,
        position: "absolute",
        right: 0,
        transform: "skewY(2.5deg)",
        transformOrigin: "100%",
        zIndex: -1
      }
    }
  },
  gridItem: {
    maxWidth: 500,
    display: "flex",
    flexDirection: "column"
  },
  paginationItem: {
    color: colors.grey["300"]
  },
  articleModal: {
    overflow: "overlay",
    display: "flex",
    justifyContent: "center",
  },
  articleBox: {
    pointerEvents: "none",
    padding: "0",
    position: "absolute",
    "&:focus": {
      outline: "none"
    },
    [theme.breakpoints.up("md")]: {
      padding: "100px 0",
    }
  },
  articlePaper: {
    pointerEvents: "initial",
    width: "100%",
    height: "100%",
    transition: "height 3s"
  }
}));

const Articles = (props) => {
  const link = useLink();
  const classes = useStyles();
  const pageCount = getPageCount();
  let { page = "1" } = useUrlQuery();
  const path = usePath();

  if (page > pageCount) {
    page = pageCount;
  }

  let modal;
  if (path) {
    modal = <Modal open
                   className={classes.articleModal}
                   onClose={link("/")}
                   BackdropProps={{
                     timeout: 500,
                   }}
    >
      <Fade timeout={500} in>
        <Box className={classes.articleBox}
             sx={{
               maxWidth: { xs: "100%", md: 800, lg: 1000 },
               width: "100%"
             }}
        >
          <Paper className={classes.articlePaper}>
            <Article slug={path} />
          </Paper>
        </Box>
      </Fade>
    </Modal>;
  } else {
    document.title = "MineClick";
  }

  return <Box className={classes.box}>
    <Grid container
          alignItems={"stretch"}
          justifyContent={"center"}
          columnSpacing={6}
          sx={{
            px: { xs: 2, md: 8, xl: 10 },
            pt: { xs: 8, md: 8 }
          }}>
      {getArticles(page - 1).map((slug, key) =>
        <Grid item xs={12} md={4} className={classes.gridItem} key={key}>
          <Article slug={slug} isThumbnail />
        </Grid>)}
    </Grid>
    <Grid
      container
      justifyContent={"center"}
      sx={{
        pb: 4,
      }}>
      <Pagination
        color={"primary"}
        page={Number.parseInt(page, 10)}
        count={getPageCount()}
        renderItem={(item) => (
          <PaginationItem
            className={classes.paginationItem}
            component={Link}
            to={`${path ? "/" + path : ""}${item.page === 1 ? "" : `/?page=${item.page}`}`}
            {...item}
          />
        )}
      />
    </Grid>
    {modal}
  </Box>;
};

export default Articles;